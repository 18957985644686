.button {
  --height: #{rem(56px)};
  --height-md: #{rem(48px)};
  --height-sm: #{rem(32px)};
  --fs: #{rem(16px)};
  --fs-md: #{rem(16px)};
  --fs-sm: #{rem(14px)};
  --min-width: #{rem(252px)};

  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: var(--height);
  margin: 0;
  min-width: var(--min-width);
  padding: 0 rem(24px);
  line-height: 1;
  font-size: var(--fs);
  color: var(--black);
  outline: 0;
  border: 2px solid var(--primary);
  border-radius: rem(40px);
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  font-weight: var(--fw-700);
  cursor: pointer;
  transition:
    color $animation,
    background $animation,
    border $animation;

  @include respond-to('laptop-l') {
    --height: #{rem(76px)};
    --height-md: #{rem(64px)};
    --height-sm: #{rem(40px)};
    --fs: #{rem(20px)};
    --fs-md: #{rem(18px)};

    min-width: rem(340px);
    padding: 0 rem(32px);
  }

  @include respond-to('4k') {
    min-width: rem(368px);
    padding: 0 rem(48px);
  }

  &:visited {
    text-decoration: none;
    color: var(--black);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover {
    &:not(:disabled) {
      background-color: var(--primary);
      text-decoration: none;
      color: var(--black);
    }
  }

  &__preloader {
    @include size(rem(32px));
    @include absolute-center;

    display: block;
  }

  &--primary {
    background-color: var(--primary);
    color: var(--black);

    &:visited {
      color: var(--black);
      background-color: var(--primary);
    }

    &:focus {
      border-color: var(--black);
    }

    &:hover {
      &:not(:disabled) {
        background-color: var(--black);
        border-color: var(--primary);
        color: var(--primary);
      }
    }
  }

  &--secondary {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: #fff;

    &:visited {
      color: #fff;
    }

    &:hover {
      background-color: rgba($secondary, 0.8);
      color: #fff;
    }
  }

  &--black {
    background-color: var(--black);
    border-color: var(--black);
    color: #fff;

    &:visited {
      color: var(--primary);
    }

    &:hover {
      background-color: var(--primary);
      color: var(--black);
    }
  }

  &--border_black {
    border-color: var(--black);

    &:hover {
      &:not(:disabled) {
        background-color: var(--black);
        border-color: var(--black);
        color: var(--primary);
      }
    }
  }

  &--border_gray {
    border-color: var(--c4);

    &:hover {
      &:not(:disabled) {
        background-color: transparent;
        border-color: var(--black);
      }
    }
  }

  &--default {
    height: rem(56px);
    font-size: rem(18px);
    min-width: auto;
  }

  &--md {
    height: var(--height-md);
    min-width: var(--min-width);
    font-size: var(--fs-md);

    @include respond-to('tablet') {
      min-width: auto;
    }
  }

  &--sm {
    height: var(--height-sm);
    min-width: var(--min-width);
    padding: 0 rem(16px);
    font-size: var(--fs-sm);

    @include respond-to('tablet') {
      min-width: auto;
    }
  }

  &--min-w_288 {
    min-width: var(--min-width);
  }

  &--w_100 {
    width: 100%;
  }

  &--min-w_auto {
    min-width: auto;
  }

  &--inline {
    padding: 0;
    border: 0;
    height: auto;
    text-decoration: underline;
    min-width: auto;

    &:hover {
      &:not(:disabled) {
        background-color: transparent;
        text-decoration: none;
      }
    }
  }

  &--kava {
    background-color: var(--kava);
    border-color: var(--kava);
    color: #fff;

    &:visited {
      color: #fff;
    }

    &:hover {
      color: var(--black);
    }

    &_border {
      background-color: transparent;
      border-color: rgba($kava, 0.4);
      color: var(--kava);

      &:visited {
        color: var(--kava);
      }
    }
  }

  &--square {
    @include font-size(16px, 20px);

    height: rem(44px);
    width: 100%;
    max-width: rem(320px);
    padding: rem(12px);
    border-radius: rem(8px);
    font-weight: 700;

    @include respond-to('mobile') {
      width: auto;
      min-width: auto;
    }
  }
}
