.google-button {
  @include reset-button;
  @include flex(null, center);
  @include font-size(20px, 24px);

  padding: 1px 10px 1px 1px;
  gap: 10px;
  background: #fff;
  box-shadow:
    0 0 1px rgb(0 0 0 / 8.4%),
    0 1px 1px rgb(0 0 0 / 16.8%);
  border-radius: 2px;
  font-family: var(--google-font);
  font-weight: 500;
  color: #757575;
}
