// Font weight
.u-fw-100 {
  font-weight: 100;
}

.u-fw-200 {
  font-weight: 200;
}

.u-fw-300 {
  font-weight: 300;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

// Text align
.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-heading-font-family {
  font-family: var( --heading-font-family);
}

// Text wrap
.u-text-nowrap {
  white-space: nowrap;
}

// Flex
.u-flex-center {
  @include flex(center, center);
}

.u-flex-wrap {
  flex-wrap: wrap;
}

// Spaces
.u-mb {
  &-0 {
    margin-bottom: 0;
  }

  &-8 {
    margin-bottom: rem(8px);
  }

  &-12 {
    margin-bottom: rem(12px);
  }

  &-16 {
    margin-bottom: rem(16px);
  }

  &-24 {
    margin-bottom: rem(24px);
  }

  &-32 {
    margin-bottom: rem(32px);
  }

  &-48 {
    margin-bottom: rem(48px);
  }
}

.u-mb-laptop-24 {
  @include respond-to('laptop') {
    margin-bottom: rem(24px);
  }
}

.u-mb-laptop-32 {
  @include respond-to('laptop') {
    margin-bottom: rem(32px);
  }
}

.u-mb-laptop-l-32 {
  @include respond-to('laptop-l') {
    margin-bottom: rem(32px);
  }
}

.u-mt {
  &-8 {
    margin-top: rem(8px);
  }

  &-16 {
    margin-top: rem(16px);
  }

  &-24 {
    margin-top: rem(24px);
  }

  &-32 {
    margin-top: rem(32px);
  }

  &-40 {
    margin-top: rem(40px);
  }

  &-56 {
    margin-top: rem(56px);
  }
}

.u-mr {
  &-16 {
    margin-right: rem(16px);
  }

  &-24 {
    margin-right: rem(24px);
  }

  &-40 {
    margin-right: rem(40px);
  }

  &-56 {
    margin-right: rem(56px);
  }
}

.pointer {
  cursor: pointer;
}

.u-gap-32 {
  gap: rem(32px);
}

.u-mobile-gap-24 {
  @media screen and (max-width: 48em) {
    gap: rem(24px);
  }
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// Paddings
.u-pt {
  &-24 {
    padding-top: rem(24px);
  }

  &-32 {
    padding-top: rem(32px);
  }
}

.u-px {
  &-24 {
    padding-left: rem(24px);
    padding-right: rem(24px);
  }
}

.u-uppercase {
  text-transform: uppercase;
}

// Color
.u-color-black {
  color: var(--black);
}

.u-color-white {
  color: var(--white);
}

.u-color-grey {
  color: var(--grey);
}

.u-color-primary {
  color: var(--primary);
}

.u-color-black-04 {
  color: rgba($black, 0.4);
}

.u-color-95 {
  color: var(--c-95);
}

// Position
.u-pos-relative {
  position: relative;
}

.u-pos-absolute {
  position: absolute;
}

// Display

// Flex
.d-flex {
  display: flex;
}

// Justify Content
.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

// Align Items
.align-items-center {
  align-items: center;
}

// Flex direction
.flex-column {
  flex-direction: column;
}

// Flex wrap
.flex-wrap {
  flex-wrap: wrap;
}

// Gap
.gap-1 {
  gap: rem(8px);
}

.gap-2 {
  gap: rem(16px);
}

.gap-3 {
  gap: rem(24px);
}

.gap-4 {
  gap: rem(32px);
}

.gap-5 {
  gap: rem(40px);
}

.u-mw-768 {
  max-width: 768px;
}

// Overflow
.of-hidden {
  overflow: hidden;
}

.u-mobile-full-width {
  @include respond-to('max-mobile') {
    width: 100%;
  }
}

.u-fs-32 {
  font-size: rem(32px);
}

.u-fs-14 {
  font-size: rem(14px);
}

.u-fs-laptop-28 {
  @include respond-to('laptop') {
    font-size: rem(28px);
  }
}

.u-border-radius-16 {
  border-radius: rem(16px);
}
