.login-page {
  @include flex(null, center, column);

  &__container {
    width: 100%;
    max-width: rem(440px);
    position: relative;
  }

  &__title {
    @include font-size(32px, 40px);

    margin-bottom: rem(32px);

    @include respond-to('laptop') {
      @include font-size(40px, 56px);
    }
  }

  &__providers-list {
    @include reset-list;
    @include flex(null, null, column);

    margin-bottom: rem(24px);
  }

  &__description {
    margin-bottom: rem(24px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-200);
  }

  &__form {
    @include flex(center, null, column);

    margin-bottom: rem(24px);
  }

  &__row {
    @include flex(space-between, center);

    margin: rem(8px) 0 rem(24px);
  }

  &__link {
    @include font-size(14px, 20px);

    font-weight: var(--fw-700);
    text-decoration: underline;
    color: var(--black);

    &:hover {
      text-decoration: none;
    }
  }

  &__text {
    @include font-size(14px, 20px);

    margin-bottom: 0;

    &--gap {
      margin: rem(24px) 0;
    }
  }

  &__btn {
    margin-top: rem(32px);
    margin-bottom: rem(16px);

    &:visited {
      color: #000;
      text-decoration: none;
    }
  }

  &__my_gap {
    margin-top: rem(32px);
    margin-bottom: rem(32px);
  }

  &__hr {
    height: 2px;
    border: 0;
    background-color: var(--f9);
  }
}
