.password-input {
  position: relative;

  &__btn {
    @include reset-button;

    position: absolute;
    right: rem(16px);
    top: rem(12px);
    cursor: pointer;
  }

  &__icon {
    &--hide {
      display: none;
    }
  }

  &__error {
    @include font-size(12px, 16px);

    display: none;
    margin-bottom: rem(16px);
    padding-left: rem(24px);
    font-family: var(--heading-font-family);
    font-weight: var(--fw-200);
    color: var(--error);

    &.is-active {
      display: block;
    }
  }
}
